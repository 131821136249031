import { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { OtpVerify } from './2Fa';
import { fetchRequest } from './Utils';

function Login() {

	const navigate = useNavigate();

	const [show2fa, setShow2fa] = useState( false );

	const [un, setUn] = useState('');
	const [pw, setPw] = useState('');
	const [otp, setOtp] = useState( null );
	const [loginErrors, setLoginErrors] = useState(false);

	const usernameChange = (e) => { setUn( e.target.value ); setLoginErrors(false); };
	const passwordChange = (e) => { setPw( e.target.value ); setLoginErrors(false); };

	const handleSubmit = async (event) => {
    event.preventDefault();

		let data = {
			username: un,
			password: pw
		}

		if( document.getElementById('otpToken') ) {
			data.token = document.getElementById('otpToken').value
		}

		try {
			let response = await fetchRequest( 'https://'+process.env.REACT_APP_API_HOST+'/api/auth/login', data )

			if( response.status === 'ok' && response.data.token) {
				if( response.data.token == 'tfa' ) {
					setShow2fa( true )
				}
        if( response.data.token ) {
          localStorage.setItem("nymJwt", response.data.token);
          navigate("/dash");
        }
      } else {
        setLoginErrors( response.message )
      }

		} catch ( err ) {
			console.log( err )
			setLoginErrors( err.message )
		}
  };

  return (
		<>
		{ show2fa &&
			<>
				<OtpVerify
					onClick={handleSubmit}
				/>
			</>
		}
		{ !show2fa &&
			<>
				<Row>
					<Col className="mx-auto col-xs-8">
				<div className="login-reg-box">
					<Form onSubmit={handleSubmit}>
      			<Form.Group className="mb-3" controlId="formBasicEmail">
        			<Form.Label>Username</Form.Label>
        			<Form.Control type="" placeholder="Username" onChange={usernameChange} />
      			</Form.Group>

      			<Form.Group className="mb-3" controlId="formBasicPassword">
        			<Form.Label>Password</Form.Label>
        			<Form.Control type="password" placeholder="Password" onChange={passwordChange} />
      			</Form.Group>
						{ !1 &&
      			<Form.Group className="mb-3" controlId="formBasicCheckbox">
        			<Form.Check type="checkbox" label="Check me out" />
      			</Form.Group>
						}
						{ loginErrors &&
							<Row>
								<Form.Group className="mb-3" controlId="formBasicCheckbox">
									<Alert variant={'danger'}>{loginErrors}</Alert>
								</Form.Group>
							</Row>
						}
							<Row>
								<div className="col-xs-12 col-lg-6 text-lg-start text-center my-2">
      						<Button className="d-block-sm full-width bold" variant="primary" type="submit">Login</Button>
								</div>
								<div className="col-xs-12 col-lg-6 my-2">
									<Link to={'/forgotpassword'}><Button style={{ float: 'left' }} variant="primary">Forgot Password</Button></Link>
            			<Link to={'/register'}><Button style={{ float: 'right' }}>Register</Button></Link>
            	</div>
						</Row>
    			</Form>
				</div>
				</Col>
				</Row>
			</>
		}
		</>
  );
}

export default Login;
