import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router";
import { Row, Col, Form, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { fetchRequest } from './Utils';

export default function AddFunds() {

	const [amountToAdd, setAmountToAdd] = useState(0);
	const [paymentType, setPaymentType] = useState('btcpay');
	const [showStripe, setShowStripe] = useState( false )

	let navigate = useNavigate();

	async function submitAddFunds( e ) {

		let amount = document.getElementById( 'addFundsAmount' ).value;
		setAmountToAdd( amount )
		e.target.setAttribute("disabled", "1")

		let elements;

		let createInvoiceData = {
    	amount: amount,
      type: paymentType
    }

		let createInvoiceRequest = await fetchRequest( 'https://'+process.env.REACT_APP_API_HOST+'/api/user/createinvoice', createInvoiceData )

		switch( createInvoiceRequest.data.type ) {
			case 'btcpay':
				window.btcpay.showInvoice( createInvoiceRequest.data.id )
				break;
			case 'stripe':
				navigate('/dash/stripe', { state: createInvoiceRequest.data.client_secret });
				break;
		}

	}

	const selectPaymentType = ( e ) => {
		setPaymentType( e )
	}

	return (
		<>
			<Row className="py-1">
				<p>Add Funds</p>
				<Col xs={6}>
					<Form>
						<Form.Control type="text" placeholder="$" id="addFundsAmount" />
					</Form>
				</Col>
				<Col xs={3}>
					<DropdownButton
      			title="Pyment Type"
      			id="dropdown-menu-align-right"
    				onSelect={selectPaymentType}
        	>
        		<Dropdown.Item eventKey="btcpay">BTC / LTC</Dropdown.Item>
          	<Dropdown.Item eventKey="stripe">Stripe / CC</Dropdown.Item>
      		</DropdownButton>
				</Col>
				<Col xs={3}>
					<Button variant="primary" onClick={submitAddFunds} >Add Funds</Button>
				</Col>
			</Row>
		</>
	);

}