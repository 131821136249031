import React from 'react';
import { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useNavigate } from "react-router";

import { fetchRequest } from './Utils';
import { ReactTabulator } from 'react-tabulator';

import { Col, Row, Button, Form, Modal } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrash, faLocationArrow } from '@fortawesome/free-solid-svg-icons'


export function EmailList() {

	let navigate = useNavigate();

	const [emailData, setEmailData] = useState( false );
	const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
	const [deleteModalVerify, setDeleteModalVerify] = useState("");
	const [showConfirmDeleteModalError, setShowConfirmDeleteModalError] = useState(false);

	const rcSso = async ( e ) => {

		let mboxid = (e.target.dataset.mboxid) ? e.target.dataset.mboxid : e.target.parentElement.dataset.mboxid
		console.log( mboxid )

	if( !mboxid ) return;

		fetch('https://'+process.env.REACT_APP_API_HOST+'/api/user/rcsso/'+mboxid, {
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + localStorage.getItem("nymJwt"),
      }
    }).then((resp) => resp.json())
    .then(r => {
				//console.log( r )
				if( r.response ) {
					window.open('https://'+process.env.REACT_APP_WEBMAIL_URL+'/?_autologin=' + r.response, '_blank');
        }
    });
	}

	useEffect(() => {
		async function getMailboxList() {
			let response = await fetchRequest( 'https://'+process.env.REACT_APP_API_HOST+'/api/mailbox/list', {} )
			setEmailData( response.data )
		}
		getMailboxList()
  }, []);

	const handleClose = () => setShowConfirmDeleteModal(false);
	const handleShow = ( e ) => {
		setShowConfirmDeleteModalError(false)
		let mboxid = (e.target.dataset.mboxid) ? e.target.dataset.mboxid : e.target.parentElement.dataset.mboxid
		setShowConfirmDeleteModal(mboxid);
	}

	const handleDelete = async ( e ) => {

		//setShowConfirmDeleteModalError(false)

		let mboxid = e.target.dataset.mboxid

		let deleteItem = emailData.find(x => {return x.id == mboxid})

		console.log( deleteItem )

		if( deleteItem.username+'@'+deleteItem.domain.main != deleteModalVerify ) {
			setShowConfirmDeleteModalError(true)
			return
		}

		fetch('https://'+process.env.REACT_APP_API_HOST+'/api/mailbox/delete', {
			method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + localStorage.getItem("nymJwt"),
      },
			//body: JSON.stringify( { mailbox_id: mboxid } )
    }).then((resp) => resp.json())
    .then(r => {
				setShowConfirmDeleteModal(false)
        console.log( r )
    });
	}

	let showCreateMailbox = () => {
		navigate('/dash/createmailbox')
	}

  return (
    <>
			<Row>
      	<Col xs={6}>
					<h3>My Email Addresses</h3>
				</Col>
				<Col className="">
					<Button className="float-end" onClick={ showCreateMailbox } size="sm">Create New Email</Button>
				</Col>
			</Row>
			<Row>
        <Col className="mx-auto" xs={11}>
          <div className="dataTable">
            { emailData && emailData.length && emailData.map(email => (
              <div className="dtRow" key={email.id}>
                <div className="dtCol tableEmailName text-truncate">{email.username}@{email.domain.domain}</div>
                <div className="dtCol tableEmailSize d-none d-lg-block text-truncate">{email.usedBytes}</div>
                <div className="dtCol tableEmailControl">
									<FontAwesomeIcon icon={faPencil} className="actionIcon" />
        					<FontAwesomeIcon icon={faTrash} className="actionIcon" onClick={handleShow} data-mboxid={email.id} />
        					<FontAwesomeIcon icon={faLocationArrow} className="actionIcon" onClick={rcSso} data-mboxid={email.id} />
                </div>
              </div>
            ))}
						{ emailData && !emailData.length &&
							<div className="dtRow">
                <div className="dtCol">No Emails</div>
							</div>
						}
          </div>
        </Col>
      </Row>
			<Modal show={showConfirmDeleteModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Email Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
					You are about to delete an email mailbox. This operation results in data loss and is irreversible.
					Please confirm by typeing in the email below:<br/><br/>
					<Form.Control type="email" placeholder="" onChange={setDeleteModalVerify}/>
					{ showConfirmDeleteModalError &&
						<div class="alert alert-danger mt-4" role="alert">Email does not match</div>
					}
				</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDelete} data-mboxid={showConfirmDeleteModal}>
            DELETE
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

