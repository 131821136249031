import { useEffect, createContext, useContext, useState } from 'react';
import { Outlet, Navigate, Link } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import { CurrentUserContext } from './Contexts';
import SideMenu from './SideMenu';
import { fetchRequest } from './Utils';

import { House, Speedometer2, RCircle } from 'react-bootstrap-icons';

import 'react-tabulator/lib/styles.css'; // required styles
//import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'react-tabulator/lib/css/tabulator_midnight.css';
import './index.css';

function ProtectedRoot() {

	let token = localStorage.getItem("nymJwt");

	const [currentUser, setCurrentUser] = useState(null);

	useEffect(() => {
		async function fetchUserInfo() {
			let request = await fetchRequest( 'https://'+process.env.REACT_APP_API_HOST+'/api/user/info' )
			if( request.status == 'ok' ) setCurrentUser( request.data )
		}
		fetchUserInfo();
	}, [])

	useEffect(() => {
		if( !document.getElementById('btcPayScript') ) {
    	const script = document.createElement('script');
    	script.setAttribute('src', 'https://btcpay.pseudonym.mx/modal/btcpay.js');
			script.id = 'btcPayScript'
    	document.head.appendChild(script);
		}

		if( !document.getElementById('stripeScript') ) {
      const script = document.createElement('script');
      script.setAttribute('src', 'https://js.stripe.com/v3/');
      script.id = 'stripeScript'
      document.head.appendChild(script);
    }

		if( !document.getElementById('bsScript') ) {
      const script = document.createElement('script');
      script.setAttribute('src', 'https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta3/dist/js/bootstrap.bundle.min.js');
      script.id = 'bsScript'
      document.head.appendChild(script);
    }

		const cssLink = document.createElement('link');
		cssLink.rel = "stylesheet"
		cssLink.src = '/css/tabulator.min.css'
		cssLink.id = 'tabluatorStyle'
		document.head.appendChild( cssLink );


  }, []);

	useEffect(() => {
		let isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

		if( isDark ) document.getElementById('tabluatorStyle').src = '/css/tabulator_midnight.min.css';

    document.documentElement.setAttribute('data-bs-theme', (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'))
  }, []);

	if( !token ) return(<Navigate to="/login" />)

  return(
		 <>
				<CurrentUserContext.Provider value={{ currentUser, setCurrentUser }} >
					<Container fluid className="overflow-hidden">

					<div className="row vh-100 overflow-auto">
        <div className="col-12 col-sm-3 col-xl-2 px-sm-2 px-0 bg-dark d-flex sticky-top">
            <div className="d-flex flex-sm-column flex-row flex-grow-1 align-items-center align-items-sm-start px-3 pt-2 text-white">
                <a href="/" className="d-flex align-items-center pb-sm-3 mb-md-0 me-md-auto text-white text-decoration-none">
                    <span className="fs-5">P<span className="d-none d-sm-inline">seudonym</span></span>
                </a>
                <ul className="nav nav-pills flex-sm-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-sm-auto mb-0 justify-content-center align-items-center align-items-sm-start" id="menu">
                    <li className="nav-item">
												<Link to={'/dash/myassets'} className="nav-link px-sm-0 px-2">
                            <House className="fs-5" /><span className="ms-1 d-none d-sm-inline">Home</span>
												</Link>
                    </li>
                    <li>
                        <Link to={'/dash/alldomains'} className="nav-link px-sm-0 px-2">
                            <Speedometer2 className="fs-5" /><span className="ms-1 d-none d-sm-inline">All Domains</span>
											</Link>
                    </li>
                    <li>
                        <Link to={'/dash/register'}  className="nav-link px-sm-0 px-2">
                            <RCircle className="fs-5" /><span className="ms-1 d-none d-sm-inline">Register Domain</span>
												</Link>
                    </li>
                </ul>
                <div className="dropdown py-sm-4 mt-sm-auto ms-auto ms-sm-0 flex-shrink-1">
                    <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="https://static.vecteezy.com/system/resources/previews/001/840/618/non_2x/picture-profile-icon-male-icon-human-or-people-sign-and-symbol-free-vector.jpg" alt="hugenerd" width="28" height="28" className="rounded-circle" />
                        <span className="d-none d-sm-inline mx-1">{currentUser && currentUser.username}</span>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                        <li><Link className="dropdown-item" to={'/dash/settings'}>Settings</Link></li>
                        <li><Link className="dropdown-item" to={'/dash/addfunds'}>Add Funds</Link></li>
												<li><span className="dropdown-item">Balance: ${currentUser && currentUser.balance_cents / 100}</span></li>
                        <li><Link className="dropdown-item" to={'/logout'}>Sign out</Link></li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="col d-flex flex-column h-sm-100">
            <main className="row overflow-auto">
                <div className="col pt-4">
                    <Outlet />
                </div>
            </main>
						{ !1 &&
            <footer className="row py-4 mt-auto">
                <div className="col"> Footer content here... </div>
            </footer>
						}
        </div>
    </div>

					</Container>
				</CurrentUserContext.Provider>
		</>
	);
}

export default ProtectedRoot;
