//import { useNavigate } from "react-router";

export function verifyPassword( password ) {

	let r = {
		status: false,
		error: []
	}

	if( password.length < 14 ) {
		r.error.push('Password must be 14 characters or more.')
	}

	if( !/[0-9]/.test( password ) ) {
		r.error.push('Password must contain at least one number.')
	}

	if( !( /[A-Z]/.test( password ) && /[a-z]/.test( password ) ) ) {
    r.error.push('Password must contain both upper and lower case.')
  }

	if( !/[!@#\$\%\^\&\*\(\)\'\?\.\,]/.test( password ) ) {
    r.error.push('Password must contain at least one special character. ( !@#$%^&*()\'?., )')
  }

	return r
}

export async function fetchRequest( url, data = false ) {

	//let navigate = useNavigate();

	let fetchOpts = {
  	method: ( data ) ? 'POST' : 'GET',
		headers: { "Content-Type": "application/json" }
  }

	if( data ) fetchOpts.body = JSON.stringify( data )

	if( localStorage.getItem("nymJwt") != null ) {
		fetchOpts.headers['Authorization'] = 'Bearer ' + localStorage.getItem("nymJwt")
	}
	//console.log( fetchOpts )

	let req = await fetch( url, fetchOpts )

	if( req.status == 401 ) {
		localStorage.removeItem("nymJwt")
		window.location.assign('/login');
		return
	}

	if( !req.ok ) {
		throw new Error(`Login error: status ${req.status}`);
	}

	let response = await req.json()

	return response
}
