import { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { verifyPassword, fetchRequest } from './Utils';

function Register() {

	const navigate = useNavigate();

	const [un, setUn] = useState('');
	const [pw, setPw] = useState('');
	const [email, setEmail] = useState('');

	const [generalValidation, setGeneralValidation] = useState('');
	const [registerErrors, setRegisterErrors] = useState(false);

	const [passwordError, setPasswordError] = useState(false);
	const [passwordMatchError, setPasswordMatchError] = useState( false );

	const usernameChange = (e) => { setUn( e.target.value ) };
	const emailChange = (e) => { setEmail( e.target.value ) };
	const passwordChange = (e) => {
		let r = verifyPassword( e.target.value );
		( r.error.length ) ? setPasswordError( r.error.join(' ') ) : setPasswordError( false );
		setPw( e.target.value )
	};
	let passwordChange2 = ( e ) => {
    let p1 = document.getElementById('regPass1').value;
    let p2 = document.getElementById('regPass2').value;
    ( p1 != p2  ) ? setPasswordMatchError( true ) : setPasswordMatchError( false );
  }

	const handleSubmit = async (event) => {
    event.preventDefault();

		if( passwordError || passwordMatchError ) return

		let data = {
			username: un,
			email: email,
			password: pw
		}

		let response = await fetchRequest( 'https://'+process.env.REACT_APP_API_HOST+'/api/auth/register', data )

		if( response.status != 'ok' ) {
			setGeneralValidation( response.message )
			return
		}

		navigate("/login");

/*
		fetch('https://'+process.env.REACT_APP_API_HOST+'/api/auth/register', {
			method: "POST",
			headers: {
      	"Content-Type": "application/json",
      	// 'Content-Type': 'application/x-www-form-urlencoded',
    	},
			body: JSON.stringify(data)
		}).then(response => {
			console.log( response )
			if (!response.ok) {
				return response.json()
			} else {
				let errResponse = response.json().then(errResponse => {
					setGeneralValidation( errResponse.msg )
				});
			}
		}).then(response => {
			navigate("/login");
		})
*/
  };

  return (
		<Row>
			<Col className="mx-auto col-xs-8">
		<div className="login-reg-box">
		<Form onSubmit={handleSubmit}>

			{
				generalValidation &&
				<>
					<Form.Group>
						<Form.Text>
							{generalValidation}
						</Form.Text>
					</Form.Group>
				</>
			}

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Username</Form.Label>
        <Form.Control type="username" placeholder="Username" id="regUsername" onChange={usernameChange} />
      </Form.Group>

			<Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control type="" placeholder="Email" id="regEmail" onChange={emailChange} />
        <Form.Text className="text-muted">
          For password reset requests. We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" placeholder="Password" id="regPass1" onChange={passwordChange} />
				{ passwordError &&
        	<Alert variant={'danger'}>
          	{passwordError}
          </Alert>
        }
      </Form.Group>
			<Form.Group className="mb-3" controlId="formBasicPassword2">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" placeholder="Retype Password" id="regPass2" onChange={passwordChange2} />
      </Form.Group>
			{ passwordMatchError &&
      	<Alert variant={'danger'}>Passwords do not match.</Alert>
      }

			{ registerErrors &&
      	<Row>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Alert variant={'danger'}>{registerErrors}</Alert>
                </Form.Group>
        </Row>
      }
              <Row>
                <div className="col-xs-12 col-lg-6 text-lg-start text-center my-2">
                  <Button className="d-block-sm full-width bold" variant="primary" type="submit">Register</Button>
                </div>
                <div className="col-xs-12 col-lg-6 my-2">
                  <Link to={'/login'}><Button style={{ float: 'right' }}>Login</Button></Link>
              </div>
            </Row>

    </Form>
		</div>
		</Col>
		</Row>
  );
}

export default Register;
