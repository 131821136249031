import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button } from 'react-bootstrap';
import { fetchRequest } from './Utils';

function Contact() {

	const [showDone, setShowDone] = useState( false );

	let handleSubmit = async ( e ) => {
		e.preventDefault()

		let data = {
			text: document.getElementById('contactText').value,
			name: document.getElementById('contactName').value,
			email: document.getElementById('contactEmail').value
		}

		let contactRequest = await fetchRequest( 'https://'+process.env.REACT_APP_API_HOST+'/api/auth/contact', data )
		setShowDone( true )
	}

  return (
		<>
			<Row>
				<Col xs={8} className="mx-auto">
					<div className="login-reg-box">
						{ showDone &&
							<>
								<span>Thank you for contacting. We'll react back out shortly if needed.</span>
							</>
						}
						{ !showDone &&
						<Form onSubmit={handleSubmit}>
      				<Form.Group className="mb-3" controlId="contactName">
        				<Form.Label>Name</Form.Label>
        				<Form.Control type="" placeholder="Name" />
      				</Form.Group>

      				<Form.Group className="mb-3" controlId="contactEmail">
        				<Form.Label>Email</Form.Label>
        				<Form.Control type="email" placeholder="Email" />
      				</Form.Group>

							<Form.Group className="mb-3" controlId="contactText">
        				<Form.Label>Example textarea</Form.Label>
      			  	<Form.Control as="textarea" rows={4} />
			      	</Form.Group>

      				<Button variant="primary" type="submit">Submit</Button>
    				</Form>
						}
					</div>
				</Col>
			</Row>
		</>
  );
}

export default Contact;
