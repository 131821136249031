import React from 'react';
import { useEffect, useState } from 'react';

import Row from 'react-bootstrap/Row';

import { DomainList } from './DomainList';
import { EmailList } from './EmailList';

export function MyAssets() {


  return (
    <>
				<>
					<EmailList
					/>
      		<br /><br />
					<DomainList />
				</>
    </>
  );
}

export default MyAssets
