import { useId, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button, Alert, Collapse } from 'react-bootstrap';
import { OtpSetup } from './2Fa';
import { ShowCurrrentBalance } from './Balance';
import { fetchRequest, verifyPassword } from './Utils';
import { CurrentUserContext, UpdateUserContext } from './Contexts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleDown } from '@fortawesome/free-solid-svg-icons'
import { useIsRTL } from 'react-bootstrap/esm/ThemeProvider';

function ChangePassword() {

	const [passwordError, setPasswordError] = useState( false );
	const [passwordMatchError, setPasswordMatchError] = useState( false );

	let passwordChange = ( e ) => {
		let r = verifyPassword( e.target.value );
		( r.error.length ) ? setPasswordError( r.error.join(' ') ) : setPasswordError( false );
	}

	let passwordChange2 = ( e ) => {
		let p1 = document.getElementById('pwChange.1').value;
		let p2 = document.getElementById('pwChange.2').value;
		( p1 != p2  ) ? setPasswordMatchError( true ) : setPasswordMatchError( false );
	}

	let changePasswordSubmit = async ( e ) => {
		e.preventDefault()

		if( passwordError || passwordMatchError ) return

		let changePasswordResponse = await fetchRequest( 'https://'+process.env.REACT_APP_API_HOST+'/api/user/changepassword', {
    	password: document.getElementById('pwChange.1').value
    })

	}

	return(
		<>
			<Row>
				<Col xs={4} className="mx-auto">
					<Form>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        			<Form.Label>Current Password</Form.Label>
        			<Form.Control type="" placeholder="" />
      			</Form.Group>
						<Form.Group className="mb-3" controlId="pwChange.1">
            	<Form.Label>New Password</Form.Label>
            	<Form.Control type="" placeholder="" onChange={ passwordChange } />
							{ passwordError &&
								<Alert variant={'danger'}>
									{passwordError}
								</Alert>
							}
          	</Form.Group>
						<Form.Group className="mb-3" controlId="pwChange.2">
            	<Form.Label>New Password Again</Form.Label>
            	<Form.Control type="" placeholder="" onChange={ passwordChange2 } />
							{ passwordMatchError &&
                <Alert variant={'danger'}>
                  Passwords do not match.
                </Alert>
              }
          	</Form.Group>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            	<Button variant="primary" onClick={ changePasswordSubmit } >Save</Button>
          	</Form.Group>
					</Form>
				</Col>
			</Row>
		</>
	)
}

function ChangePlan() {

	const [showSelectPlan, setShowSelectPlan] = useState( false );	
	const [showUpgrdeCost, setShowUpgradeCost] = useState( 0 );

	const {
    	currentUser,
    	setCurrentUser
  	} = useContext(CurrentUserContext);

	let finalizePlanChange = async (e) => {
		let request = await fetchRequest('https://'+process.env.REACT_APP_API_HOST+'/api/user/changeplan', { planId: showSelectPlan, confirm: true });

		setCurrentUser( null )
		setShowSelectPlan( false )
	}

	let selectPlan = async (e) => {
		var t = e.target
		if( !t.dataset.plan ) t = t.closest('.plan')

		if( currentUser.plan && currentUser.plan.id == t.dataset.plan ) return

		console.log( t.dataset.plan )

		let request = await fetchRequest('https://'+process.env.REACT_APP_API_HOST+'/api/user/changeplan', { planId: t.dataset.plan, confirm: false });

		setShowUpgradeCost( request.data.upgradePrice )
		setShowSelectPlan( t.dataset.plan )
	}

	return (
		<>
			{showSelectPlan &&
				<Row className="mt-4">
					<div className="mb-3 text-center">You are upgrading your plan. You'll be upgraded to a :plan: at a cost of ${showUpgrdeCost / 100}</div>
					<Button onClick={finalizePlanChange}>Upgrade</Button>
				</Row>
			}
			{!showSelectPlan &&
				<Row className="mt-4">

					{currentUser && !currentUser.plan &&
						<Col xs={12}>
							<Row>
								<h5 className="text-center">No Plan Selected</h5>
							</Row>
						</Col>

					}

					<Col xs={2} className="mx-auto plan" onClick={selectPlan} data-plan="1">

						<div className={"card mb-4 rounded-3 shadow-sm " + ((currentUser && currentUser.plan && currentUser.plan.id == 1) ? 'border-primary' : '')}>
							<div className={"card-header py-3" + ((currentUser && currentUser.plan && currentUser.plan.id == 1) ? 'text-bg-primary border-primary' : '')}>
								<h4 class="my-0 fw-normal">Minor</h4>
							</div>
							<div class="card-body">
								<h1 class="card-title pricing-card-title">$45<small class="text-body-secondary fw-light">/yr</small></h1>
								<ul class="list-unstyled mt-3 mb-4">
									<li>Unlimited email addresses</li>
									<li>10 GB of storage</li>
									<li>Priority email support</li>
									<li>Help center access</li>
								</ul>
							</div>
						</div>

					</Col>

					<Col xs={2} className="mx-auto plan" onClick={selectPlan} data-plan="2">
						<div className={"card mb-4 rounded-3 shadow-sm " + ((currentUser && currentUser.plan && currentUser.plan.id == 2) ? 'border-primary' : '')}>
							<div className={"card-header py-3" + ((currentUser && currentUser.plan && currentUser.plan.id == 2) ? 'text-bg-primary border-primary' : '')}>
								<h4 class="my-0 fw-normal">Major</h4>
							</div>
							<div class="card-body">
								<h1 class="card-title pricing-card-title">$65<small class="text-body-secondary fw-light">/yr</small></h1>
								<ul class="list-unstyled mt-3 mb-4">
									<li>Unlimited email addresses</li>
									<li>25 GB of storage</li>
									<li>Priority email support</li>
									<li>Help center access</li>
								</ul>
							</div>
						</div>
					</Col>

					<Col xs={2} className="mx-auto plan" onClick={selectPlan} data-plan="3">
						<div className={"card mb-4 rounded-3 shadow-sm " + ((currentUser && currentUser.plan && currentUser.plan.id == 3) ? 'border-primary' : '')}>
							<div className={"card-header py-3" + ((currentUser && currentUser.plan && currentUser.plan.id == 3) ? 'text-bg-primary border-primary' : '')}>
								<h4 class="my-0 fw-normal">Mega</h4>
							</div>
							<div class="card-body">
								<h1 class="card-title pricing-card-title">$85<small class="text-body-secondary fw-light">/yr</small></h1>
								<ul class="list-unstyled mt-3 mb-4">
									<li>Unlimited email addresses</li>
									<li>50 GB of storage</li>
									<li>Priority email support</li>
									<li>Help center access</li>
								</ul>
							</div>
						</div>
					</Col>
				</Row>
			}
		</>
	)
}

function Settings() {

	//const [showOtpVerify, setShowOtpVerify] = useState( false );
	const [verifyTrigger, setVerifyTrigger] = useState( false );

	const [showOtpCollpse, setShowOtpCollpse] = useState( false );
	const [showPasswordCollpse, setShowPasswordCollpse] = useState( false );
	const [showPlanCollpse, setShowPlanCollpse] = useState( false );

	let clickVerifyBtn = () => {
		setVerifyTrigger( true );
	}

	let expandSettings = (e) => {
		var t = e.target
		if( !t.dataset.tab ) t = t.closest('.col')
		let tab = t.dataset.tab;
		console.log( tab )

		switch( tab ) {
			case 'otp':
				( showOtpCollpse ) 
					? document.getElementById(tab+'Icon').classList.add('rotateicon') 
					: document.getElementById(tab+'Icon').classList.remove('rotateicon');
				setShowOtpCollpse( !showOtpCollpse );
				break;
			case 'password':
				( showPasswordCollpse ) 
					? document.getElementById(tab+'Icon').classList.add('rotateicon') 
					: document.getElementById(tab+'Icon').classList.remove('rotateicon');
				setShowPasswordCollpse( !showPasswordCollpse );
				break;
			case 'plan':
				( showPlanCollpse ) 
					? document.getElementById(tab+'Icon').classList.add('rotateicon') 
					: document.getElementById(tab+'Icon').classList.remove('rotateicon');
					setShowPlanCollpse( !showPlanCollpse );
				break;
		}
	}

  return (
	  <Col>
		  <h3>Settings</h3>

		  <Row className="settingItem m-3 p-1">
			  <Col onClick={expandSettings} data-tab="otp">
				  <span className="float-start"><h5 style={{ marginBottom: 0 }}>Two Factor Authentication</h5></span>
				  <span className="float-end">
					  <FontAwesomeIcon icon={faCircleDown} className="actionIcon rotateicon" id="otpIcon" />
				  </span>
			  </Col>
			  <Collapse in={showOtpCollpse}>
				  <div id="example-collapse-text" className="mt-2">
					  <Row className="py-1">
						  <Col xs={6} className="d-flex align-items-center">
							  To enable 2FA, scan the QRcode to the right and click Verify.
						  </Col>
						  <Col xs={2} className="d-flex align-items-center">
							  <Button variant="primary" onClick={clickVerifyBtn} >Verify</Button>
						  </Col>
						  <Col xs={4}>
							  <OtpSetup
								  verifyTrigger={verifyTrigger}
								  setVerifyTrigger={setVerifyTrigger}
							  />
						  </Col>
					  </Row>
				  </div>
			  </Collapse>
		  </Row>

		  <Row className="settingItem m-3 p-1">
			  <Col onClick={expandSettings} data-tab="password">
				  <span className="float-start"><h5 style={{ marginBottom: 0 }}>Change Password</h5></span>
				  <span className="float-end">
					  <FontAwesomeIcon icon={faCircleDown} className="actionIcon rotateicon" id="passwordIcon" />
				  </span>
			  </Col>
			  <Collapse in={showPasswordCollpse}>
				  <div id="example-collapse-text" className="mt-2">
					  <ChangePassword />
				  </div>
			  </Collapse>
		  </Row>

		  <Row className="settingItem m-3 p-1">
			  <Col onClick={expandSettings} data-tab="plan">
				  <span className="float-start"><h5 style={{ marginBottom: 0 }}>Current Plan</h5></span>
				  <span className="float-end">
					  <FontAwesomeIcon icon={faCircleDown} className="actionIcon rotateicon" id="planIcon" />
				  </span>
			  </Col>
			  <Collapse in={showPlanCollpse}>
			  	<div id="example-collapse-text" className="mt-2">
					<ChangePlan />		
				</div>			
			  </Collapse>
		  </Row>

	  </Col>
  );
}

export default Settings;
